import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Home from './Containers/Home'
import SchoolList from './Containers/SchoolList'
import ApplicationForm from './Containers/ApplicationForm'
import UniversityLogin from './Containers/university/UniversityLogin'
import UniversityApplicationForm from './Containers/university/UniversityApplicationForm'
import PaymentForm from './Containers/PaymentForm'
import PaymentFormAdmission from './Containers/PaymentFormAdmission'
import ViewFormAdmission from './Containers/ViewFormAdmission'
import DownloadForm from './Containers/DownloadForm'
import PublicLogin from './Containers/publicuniversity/PublicLogin'
import PublicApplicationForm from './Containers/publicuniversity/PublicApplicationForm'
import AdmissionForm from './Containers/admission/AdmissionForm';
import AdmissionFormEdit from './Containers/admission/AdmissionFormEdit';
import DownloadFormAdmission from './Containers/DownloadFormAdmission'
import ShortAdmissionForm from './Containers/admission/ShortAdmissionForm';
import DownloadFormShortAdmission from './Containers/DownloadFormShortAdmission'
import PaymentFormShortAdmission from './Containers/PaymentFormShortAdmission'
import ViewFormShortAdmission from './Containers/ViewFormShortAdmission'
import SchoolLogin from './Containers/newschool/Login'
import SchoolApplicationForm from './Containers/newschool/SchoolApplicationForm'
import AlumniForm from './Containers/alumni/AlumniForm'
import ViewFormAlumni from './Containers/alumni/ViewFormAlumni'
import PaymentAlumni from './Containers/alumni/PaymentAlumni'
import DownloadAlumni from './Containers/alumni/DownloadAlumni'
import LargeAdmissionForm from './Containers/admission/LargeAdmissionForm';
import PaymentFormLargeAdmission from './Containers/PaymentFormLargeAdmission';
import ViewFormLargeAdmission from './Containers/ViewFormLargeAdmission';
import DownloadFormLargeAdmission from './Containers/DownloadFormLargeAdmission';

const App = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        /*College Start*/
        <Route exact path="/login" component={SchoolList} />
        <Route
          exact
          path="/online-applicant-info/:id"
          component={ApplicationForm}
        />
         /*College End*/
        /*New School Start*/
        <Route exact path="/school-login" component={SchoolLogin} />
        <Route
          exact
          path="/online-applicant-info-school/:id"
          component={SchoolApplicationForm}
        />
         /*New School End*/
        <Route exact path="/university/login" component={UniversityLogin} />
        <Route
          exact
          path="/university/online-applicant-info/:id"
          component={UniversityApplicationForm}
        />
        <Route exact path="/public/login" component={PublicLogin} />
        <Route
          exact
          path="/online-admission/:id"
          component={AdmissionForm}
        />
        <Route
          exact
          path="/online-admission-edit/:id"
          component={AdmissionFormEdit}
        />
        <Route
          exact
          path="/short-online-admission/:id"
          component={ShortAdmissionForm}
        />
        <Route
          exact
          path="/online-admission-payment/:id"
          component={PaymentFormAdmission}
        />
        <Route
          exact
          path="/short-online-admission-payment/:id"
          component={PaymentFormShortAdmission}
        />
        <Route
          exact
          path="/online-admission-download/:id"
          component={DownloadFormAdmission}
        />
        <Route
          exact
          path="/short-online-admission-download/:id"
          component={DownloadFormShortAdmission}
        />
        <Route
          exact
          path="/online-admission-view/:id"
          component={ViewFormAdmission}
        />
        <Route
          exact
          path="/short-online-admission-view/:id"
          component={ViewFormShortAdmission}
        />
        <Route
          exact
          path="/online-applicant-fee-payment/:id"
          component={PaymentForm}
        />
        <Route
          exact
          path="/online-applicant-confirmation-paper/"
          component={DownloadForm}
        />
        {/* Alumni Form */}
        <Route
          exact
          path="/alumni-application/:id"
          component={AlumniForm}
        />
        <Route
          exact
          path="/alumni-view/:id"
          component={ViewFormAlumni}
        />
        <Route
          exact
          path="/alumni-payment/:id"
          component={PaymentAlumni}
        />
        <Route
          exact
          path="/alumni-download/:id"
          component={DownloadAlumni}
        />


{/* ========================================= */}

        <Route
          exact
          path="/large-online-admission/:id"
          component={LargeAdmissionForm}
        />

      <Route
          exact
          path="/large-online-admission-payment/:id"
          component={PaymentFormLargeAdmission}
        />

        <Route
          exact
          path="/large-online-admission-view/:id"
          component={ViewFormLargeAdmission}
        />

<Route
          exact
          path="/large-online-admission-download/:id"
          component={DownloadFormLargeAdmission}
        />
        
        
      </Switch>
    </BrowserRouter>
  );
};

export default App;
